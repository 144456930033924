import moment from 'moment'; // Moment.js

export const getFormattedDate = (inp_date) => {
  
    // const isoDateString = inp_date;
    // const date = new Date(isoDateString);

    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    // const day = String(date.getDate()).padStart(2, '0');

    // const formattedDate = `${year}-${month}-${day}`;
    return  moment(inp_date).format('DD-MM-YYYY')
  } 
  

  export const getFormattedDateTime = (inp_date) => {
  
    return moment(inp_date).format('DD-MM-YYYY HH:mm:ss')
  } 
  